.HeadstonePicker,
.GenericGranitePicker,
.GlobalGranitePicker,
.FrameGranitePicker,
.VeneerGranitePicker {
  h4 {
    @extend %reset;
    color: $color-grey-6;
    font: font-default(20);
    margin: 20px 20px 10px 20px;
    text-transform: uppercase;
  }

  .GraniteCard,
  .HeadstoneCard {
    @extend %list-item;
    background: #fff;
    border: none;
    margin: 0;
    // min-height: 120px;
    // position: relative;
    text-decoration: none;
    box-shadow: none;
    .LazyImageB {
      img:not(.configfLoader) {
        transform: scale(1.2);
        position: absolute;
        top: 15%;
        left: 3%;
      }
    }
    .pictos {
      right: 0;
      position: absolute;
      top: 0;
      z-index: 50;
      ul{
        list-style: none;
        li{
          margin: 4px;
          padding: 5px;
          img {
            border-radius: 25px;
          }
        }
      } 
    }

    &.selected {
      cursor: default;
    }

    &.disabled {
      cursor: not-allowed;
      position: relative;

      // &:hover {
        .DisabledMessage {
          opacity: 1 !important;
          background: rgba(255, 255, 255, 0.5);
        }
      // }

      .DisabledMessage {
        @extend %vertically-centered-container;
        bottom: 0;
        display: table-cell;
        left: 0;
        @include opacity(0);
        position: absolute;
        right: 0;
        // top: 0;
        @include transition(all 0.4s ease);
        z-index: 650;
        height: 100%;
      }
    }

    p {
      @include box-sizing(border-box);
      color: #6A7782;
      display: inline-block;
      font: font-default(14, normal, 20px);
      @media screen and (max-width: 576px) {
        font: font-default(10, normal, 20px);
      }
      margin: 0 auto;
      padding: 0 20px;
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;
      vertical-align: middle;
      width: 100%;
    }

    &.showConfirm .ElemConfirm {
      display: flex;
    }

    &.disabledAccessory {
      cursor: pointer;
    }

    &.showConfirm {
      &:hover {
        background: $color-grey-3;
        .DisabledMessage {
          @include opacity(0);
        }
        img {
          @include opacity(1);
        }
      }
    }
  }

  .Infos {
    // left: 0;
    // position: absolute;
    // top: 0;
    // z-index: 50;
    // width: 100% !important;
    // margin-top: 10px;
    display: flex;
    justify-content: center;
    text-align: center;
    span {
      color: var(--text-medium);
      font-family: Raleway;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 131px;
      margin-top: 14px;
    }
  }

  .GranitePreviewCard,
  .HeadstoneCard {
    h3 {
      @extend %reset;
      color: var(--text-dark);
      font: font-default(16);
      // padding: 15px;
      position: relative;
      z-index: 9999;
      text-transform: capitalize;
    }
    &.selected {
      h3 {
        color: var(--secondary1);
      }
    }
    &:hover {
      box-shadow: none;
    }
    figure {
      // @include figure-height-relative(640/360);
      padding-bottom: 67%;
      /* @include smartphone {
        img {
          transform: translate(15%, 20%);
        }
      } */
    }
    &.accessory-granite-card {
      figure{
        padding-bottom: 5%;
      }
      .Infos{
        bottom: 28px !important;
      }
    }
    .plusGranite {
      font-size: 29px;
      color: #ffffff;
      height: 100%;
      text-align: center;
      width: 100%;

      text-transform: uppercase;
      div{
        display: inline-flex;
        padding: 9% 15%;
        div:last-child{
          width: 50%;
          text-align: initial;
          padding-left: 7%;
        }
        div:first-child{
          padding-right: 0px;
          font-size: 85px;

        }

      } 

    }
    @media only screen and (max-width: 900px) {
      .plusGranite {
        font-size: 23px;
        div{
          padding: 4% 12%;
        }
        @include smartphone {
          font-size: 19px;
          div{
            padding: 8% 9%;
            div:first-child{
              font-size: 59px; 
            }
          }


        }
      }
    }
  }
  
  .GranitePreviewCard {
    .CatalogGraniteCardContent {
      h3 {
        padding: 0;
      }
      .Infos .Price span {
        display: block;
        font-size: 14px;
        padding: 0;
        small {
          font-size: 70%;
        }
      }
    }
  }

  .GraniteTextureCard {
    height: 79px;
    width: 131px;
    border-radius: 15px;
    @include smartphone {
      width: unset;
      height: 50px;
    }
    figure {
      height: 270px;
      width: 180px;
      position: relative;
      img {
        position: relative;
        height: 100%;
        width: 100%;
      }
    }
    .plusGranite{
      font-size:24px;
      color:#ffffff;
      text-transform: uppercase;
      height: 270px;
      width: 180px;
      div{
        display: inline-flex;
        padding: 28% 6%;
        div:first-child{
          padding-right: 7%;
          font-size: 70px;
        }
      }

    }
    .Infos {
      width: 100%;
      left: 0;
      position: absolute;
      top: 0;
      z-index: 50;
      height: auto;
    }
    h3 {
      @extend %reset;
      // http://colorzilla.com/gradient-editor/#000000+0,000000+100&0.5+0,0+100
      @include filter-gradient(
        rgba(0, 0, 0, 0.5),
        rgba(0, 0, 0, 0),
        vertical
      ); // IE6-9
      @include background-image(
        linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%)
      );
      color: $color-white;
      font: font-default(16);
      padding: 15px;
      @include text-shadow(1px 1px 0 #000);
      text-transform: capitalize;
      width: 100%;
      display: flex;
      justify-content: center;
    }
    &.selected {
      border: 2px solid var(--secondary1);
      margin: -2px;
      h3 {
        background: var(--secondary1);
      }
    }
  }
}

.FamilyListViewContainer {
  display: flex;
}

.FrameGranitePicker,
.VeneerGranitePicker {
  .ListViewChunk {
    /* Only non fixed cards */
    overflow: visible;
    .ListViewItem {
      width: 180px;
    }
  }
}

.FrameGranitePickerPopupModal,
.VeneerGranitePickerPopupModal,
.AccessoryGranitePickerPopupModal:not(.accessories-granite),
.AccessoriesGranitePickerPopupModal:not(.accessories-granite) {
  .Scrollable > .ListView {
    .ListViewChunk {
      overflow: visible;
    }
    .ListViewItem {
      // width: auto;
      .granite-wrapper {
        // height: 150px;
      }
      // @include smartphone {
      //   width: calc(50% - 1rem);
      // }
    }
  }
}

.accessories-granite {
  .Scrollable > .ListView {
    .ListViewChunk {
      overflow: visible;
    }
    .ListViewItem {
      // max-height: 230px;
      .ListViewItemContainer {
        margin: 0;
        .accessory-wrapper {
          .accessory-granite-card {
            // height: 250px;
            // width: auto;
            // border-radius: 15px;
            figure {
              // height: 250px;
              // width: auto;
              // position: relative;
              cursor: pointer;
              &:hover {
                -webkit-transform: scale(1.05);
                transform: scale(1.05);
                transition: all 0.15s ease;
              }
              img {
                position: relative;
                height: 100%;
                width: 100%;
                min-height: 168px;
                margin-top: 8%;
              }
            }
            .Infos {
              width: 100%;
              z-index: 99999;
              position: relative;
              display: block;
            }
            &.selected {
              // border: 2px solid var(--secondary1);
              // margin: -2px;
              .Infos > span {
                color: var(--secondary1) !important;
              }
            }
          }
        }
      }
    }
  }  
}

.AccessoriesGranitePickerTabs {
  /*
  height: 60px;
  margin-bottom: -2px;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0 15px;
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  */
  width: 50%;
  display: flex;
  .Accessory {
    @extend %reset, %border-radius;
    @include border-bottom-radius(0);
    background: $color-grey-D;
    border: 1px solid $color-grey-D;
    border-bottom: 0;
    cursor: pointer;
    // display: inline-block;
    margin-right: 2px;
    padding: 9px;
    text-align: left;
    text-decoration: none;
    // width: 20%;
    // max-width: 150px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    &.active {
      background-color: #ededed;
    }
    .Reference {
      color: var(--text-dark);
      font: font-default(16, normal, 20px);
      font-variant-numeric: lining-nums proportional-nums;
      text-transform: uppercase;
    }
    .Description {
      @extend %text-overflow;
      color: var((--text-medium));
      font: font-default(16, normal, 20px);
      text-transform: uppercase;
    }
  }
  @for $i from 4 to 6 {
    .Accessory:first-child:nth-last-child(#{$i}),
    .Accessory:first-child:nth-last-child(#{$i}) ~ .Accessory {
      // If 4 to 6 accessory items
      //width: 160px;
    }
  }
  @for $i from 7 to 12 {
    .Accessory:first-child:nth-last-child(#{$i}),
    .Accessory:first-child:nth-last-child(#{$i}) ~ .Accessory {
      // If 7 to 12 accessory items
      //width: 100px;
    }
  }
  @for $i from 13 to 20 {
    .Accessory:first-child:nth-last-child(#{$i}),
    .Accessory:first-child:nth-last-child(#{$i}) ~ .Accessory {
      // If 13 to 20 accessory items
      //width: 20%;
    }
  }
}

.AccessoriesGraniteTabContent .ScrollListView {
  // Hauteur du header avec les onglets d'accessoires
  // top: 117px !important;
}

.GlobalGranitePicker {
  .ListViewItemContainer {
    margin-left: 0;
    margin-right: 0;
  }
}

.AccessoriesGranitePickerPopupModalOverlay {
  .GenericGranitePicker {
    h4 {
      margin: 45px 20px 10px 20px;
    }
    .LazyImageB {
      img {
        left: 0% !important;
      }
    }
  }
}
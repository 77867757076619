/**
 * Fichier global chargé au lancement de l'application
 */
@import url("https://fonts.googleapis.com/css2?family=Cookie&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');
@import "./normalize";
@import "./external";
@import "./main.scss";
@import "components/carousel";
@import "components/typography";
@import "components/images";
@import "components/fabric";
@import "components/forms";
@import "components/lists";
@import "components/modal";
@import "components/steps";
@import "components/viewer3d";
@import "components/notification";
@import "components/introjs";
@import "components/introjscustom";
@import "components/messages";
@import "components/exit";
@import "components/optionpanel";
@import "pages/catalog";
@import "pages/configuration";
@import "pages/monument";
@import "pages/granite";
@import "pages/patterns";
@import "pages/accessories";
@import "pages/completion";
@import "pages/adminfamily";
@import "pages/selection";
@import "family/family";
@import "family/fcatalog";
@import "family/fheader";
@import "family/ffooter";
@import "family/fgranites";
@import "family/fengravings";
@import "family/fpatterns";
@import "family/faccessory";
@import "family/ffinalization";
@import "pages/admin";
@import "family/fcustomize";
@import "family_space/main";
@import "family_space/sactivity";
@import "family_space/sclient";
@import "family_space/scompare";
@import "family_space/screate";
@import "family_space/ssocial";
@import "family_space/spot";
@import "family_space/tooltip";

@font-face {
  font-family: "sukhumvit";
  src: url("../fonts/sukhumvit-set_text.woff-webfont.woff") format("woff"),
  url("../fonts/sukhumvit-set_text.woff.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}


html,
body {
  @extend %reset;
  @include font-smoothing();
  // font-size: 62.5%; // Sets 10px = 1em
  height: 100%;
  min-height: 100%;
  @media screen and (max-width: 768px){
    height: 100vh;
  }
}

body {
  /* background: $color-grey-D url("../images/background.png") repeat;
  font: font-default();
  -ms-overflow-style: -ms-autohiding-scrollbar; */
}

/* .body-background {
  background: $color-grey-D url("../images/background.png") repeat;
} */

.material-icons {
  @extend %material-icon;
}

.button-group {
  @extend %button-group;
}

.loading-wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  text-align: center;
  background: white;
  z-index: -999;

  .loading {
    margin-top: 8%;
    width: 250px;
  }

  @include smartphone {
    padding-top: 50%;
  }
}

.fcustomize {
  background-color: #FFFFFF;
  @media screen and (max-width: 480px){
    overflow: hidden;
    height: 100%;
  }
  .content {
    position: fixed;
    top: 70px;
    @media screen and (max-width: 768px){
    position: inherit;
    }
  }
  .viewer-3d-wrapper {
    position: fixed;
    margin-left: 300px;
    top: 88px;
    height: 80%;
    width: 80%;
  }

  // .fTopBarContainer{
  //   z-index: 10;
  //   height: 70px;
  //   background-color: #EAEEF2;
  //   color: #324854;
  //   font-size: 22px;
  //   font-weight: 700;
  //   display: flex;
  //   align-items: center;
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   box-shadow: rgba(0, 0, 0, 0.24) 0 1px 5px;
  //   width: 100%;
  //   .logoContainer{
  //     margin-top: unset;
  //     @media screen and (max-width: 576px){
  //       margin-bottom: unset;
  //     }
  //     .clientLogo{
  //       margin-left: 15px;
  //       @media screen and (max-width: 768px){
  //         margin-left: 5px;
  //       }
  //     }
  //   }
  //   @media screen and (max-width: 480px){
  //     position: fixed;
  //     top: 0;
  //   }
  //   .monumentRef{
  //     font-family: $RalewayFont;
  //     padding-left: 32px;
  //     color:  var(--fcpgray);
  //     font-size: 28px;
  //     font-weight: 700;
  //     letter-spacing: 0.5px;
  //     @media screen and (max-width:576px){
  //       font-size: 20px;
  //       padding-left: 10px;
  //     }
  //   }
  //   .infosButtons {
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     margin-left: auto;
  //     margin-right: 5px;
  //     .infoBtn{
  //       // width: 48px;
  //       // height: 48px;
  //       margin-right: 8px;
  //       color: #6A7782;
  //       @media screen and (max-width: 576px){
  //         margin-right: 0;
  //       }
  //       cursor: pointer;
  //       fill: var(fc--orange);
  //       padding: 8px 14px;
  //       display: flex;
  //       align-items: center;
  //       justify-content: center;
  //       background: rgba(255, 255, 255, 0.5);
  //       border: 1px solid #4D616B;
  //       border-radius: 50px;
  //       @media screen and (max-width: 768px){
  //         background: unset;
  //         border: 0;
  //         padding: 0 4px;
  //         color: #324854;
  //       }
  //       .svgContainer{
  //         svg{
  //           width:24px;
  //           height:24px;
  //           padding: 2px 3px;
  //         }
  //         p{
  //           display: flex;
  //           margin: unset;
  //           span{
  //             font-weight: 500;
  //             font-size: 16px;
  //             margin-left: 4px;
  //             color: #4D616B;
  //             align-self: center;
  //             @media screen and (max-width: 768px){
  //               font-size: 14px;
  //             }
  //           }
  //           @media screen and (max-width: 768px){
  //             flex-direction: column;
  //             align-items: center;
  //           }
  //         }
  //       }
  //       /*&.open{
  //         border: 1px solid #C1CABE;
  //         border-radius: 30px;
  //         padding: 10px;
  //       } */
  //       &.infoQuoteAsk{
  //         // background-color: var(--fcorange);
  //         background-color: var(--f-main-color);
  //         /* box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15); */
  //         border: 0;
  //         .svgContainer{
  //           span{
  //             /* color: #001F2A; */
  //             color: var(--f-font-color);
  //           }
  //         }
  //       }
  //       /* &.infoFamilySpace{
  //         width: 200px;
  //         padding: 10px;
  //         border: 1px solid #CCD4DB;
  //         border-radius: 4px;
  //         cursor: pointer;
  //         @media screen and (max-width: 576px){
  //           border-radius: 50px;
  //           width: auto;
  //         }
  //         &:hover{
  //           border: 1px solid var(--fcorange);
  //           background: var(--fcorange);
  //         }
  //         .svgContainer{
  //           margin-left: 2px;
  //         }
  //         .sfName{
  //           font-family: $sukhumvitFont;
  //           font-size: 14pt;
  //           text-transform: uppercase;
  //           font-weight: lighter;
  //           color: var(--fcdarkstlblue);
  //           margin-left: 5px;
  //           margin-right: 5px;
  //           text-overflow: ellipsis;
  //           white-space: nowrap;
  //           overflow: hidden;
  //           width: 200px;
  //           text-align: center;
  //         }
  //       }*/
  //     }
  //   }
  // }

  .monumentImg-wrapper {
    position: fixed;
    background-color: white;
    height: 100%;
    width: 100%;
    z-index: -1;
    top: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 480px){
      position: relative;
      height: 100vh;
      width: 95vw;
      z-index: unset;
      display: block;
      justify-content: unset;
      align-items: unset;
    }
    .monumentImg{
      width: 100%;
      height: 100%;
      object-fit: contain;
      margin-left: 114px;
      margin-left:  15vw;
      @media screen and (max-width: 768px){
        //margin-left: 10px;
        margin-left: unset;
        margin-right: unset;
        position: absolute;
        height: 500px;
        top: 50%;
        left: 5vw;
        transform: translate(0,-65%);
        //height: 77vh;
      }
    }
  }
  .side-actions-wrapper {
    z-index: 9;
    position: relative;
    left: 0;
    overflow-y: auto;
    width: 114px;
    min-height: calc(100vh - 70px);
    max-height: calc(100vh - 70px);
    background-color: #EAEEF2;
    color: #324854;
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
    @media screen and (min-height: 620px) {
      align-items: center;
    }
    @media screen and (max-width: 768px){
      position: fixed;
      align-items: center;
      min-height: 90px;
      max-height: 90px;
    }
    justify-content: center;
    .list-actions{
      .action-item{
        &:hover{
          .svgContainer{
            background: #D4DADF;
            border-radius: 50px;
          }
          /* .txtContainer{
            background: #D4DADF;
            border-radius: 25px;
          } */
        }
      }
    }
    @media screen and (max-width: 768px) {
      top: unset;
      bottom: 0;
      width: 100%;
      height: 90px;
      z-index: 500;
      .list-actions{
        @media screen and (max-width: 768px){
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
        }
        svg{
          @media screen and (max-width: 768px){
            width: 26px;
            height: auto;
            min-height: 20px;
          }
        }
      }
    }
    .actions-box {
      /*background-color: var(--fcblue);
      border-top-right-radius: 10px;*/
      padding-top: 15px;
      padding-bottom: 5px;
      width: 100%;
      // margin-top: 70px;
      @media screen and (max-width: 768px) {
        padding-top: unset;
        padding-bottom: unset;
        margin-top: unset;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      ul {
        padding-left: 0px;
        .action-item {
          padding-left: 0;
          list-style: none;
          font-size: 14pt;
          font-style: Cuprum;
          padding-top: 12px;
          padding-bottom: 12px;
          @media screen and (max-width: 768px){
            margin-top: 5px;
            margin-bottom: unset;
          }
          margin-right: 2%;
          border-bottom: 1px solid rgba(255, 255, 255, .3);
          cursor: pointer;
          text-align: center;
          .svgContainer{
            display: block;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 5px;
            padding-top: 2px;
            padding-bottom: 2px;
            svg {
              display: block;
              margin-left: auto;
              margin-right: auto;
              @media screen and (max-width: 320px) {
                width: 32px;
              }
            }
            @media screen and (max-width: 320px) {
              width: 32px;
            }
          }
          .txtContainer{
            font-family: 'sukhumvit',arial,helvetica,sans-serif;
            width: 100%;
            padding-top: 2px;
            padding-bottom: 2px;
            @media screen and (max-width: 768px) {
              line-height: 20px;
              font-size: 16px;
            }
            @media screen and (max-width: 320px) {
              font-size: 15px;
            }
          }
        }
        .action-item-disabled {
          cursor: not-allowed;
        }
        // li:last-child {
        //   //border-bottom-style: none!important;
        //   //padding-bottom: 0px!important;
        //   /*@media screen and (max-width: 768px){
        //     padding-bottom:  unset !important;
        //   }*/
        // }
      }
    }

    .buttons-box {
      /*background-color: #3B4752;
      border-bottom-right-radius: 10px;*/

      .buttons-group {
        margin-top: 7px;
        margin-bottom: 15px;

        .button-side-item {
          background: #e6e6e6 0% 0% no-repeat padding-box;
          width: 206px;
          min-height: 40px;
          margin-left: 22px;
          border-radius: 50px;
          color: #2A3032;
          font-style: Cuprum;
          font-size: 14pt;
          letter-spacing: 0px;
          margin-top: 8px;

          &.saveicon {
            display: flex;
            align-items: center;
            letter-spacing: -0.27px;
          }

          div {
            padding: 5px;
          }

          svg {
            color:var(--fcblue);
            font-size: 28px;
          }
        }

      }
    }

    .d-view-box {
      margin-top: 15px;
      text-align: center;

      .button-site-item-3d {
        width: 64px;
        height: 64px;
        border-radius: 20px;
        border-style: none;
        background: var(--fcorange) 0% 0% no-repeat padding-box;
        color: #FFFFFF;
        font-family: Cuprum;
        font-size: 12pt;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          //margin-right: 10px;

          &.flower { height: 30px; padding-bottom: 5px; }
        }

        &.hideFlower {
          background: #E6E6E6 0% 0% no-repeat padding-box!important;
          color: #0E0E0F;
        }
      }
    }
  }

  .Fquote-informations {
    position: absolute;
    // width: 312px;
    left: 380px;
    top: 45%;
    background: #EFEFEF 0% 0% no-repeat padding-box;
    padding: 15px 15px 15px 25px;

    .title {
      font-family: Cuprum;
      font-size: 12pt;
    }

    .subtitle {
      font-family: Cuprum;
      font-size: 12pt;
      color: var(--fcblue); //CHANGED#0095E8
    }

    ul {
      margin-top: 10px;
      padding-left: 0;
    }
    ul li {
      list-style: none;
      margin-bottom: 5px;

      span {
        margin-left: 10px;
        top: -5px;
        font-family: Cuprum;
        font-size: 12pt;
      }
    }

    ul li:before {
      border-radius: 100%;
      counter-increment: section;
      content:counter(section);
      background: var(--fcblue); //CHANGED#0095E8
      color: var(--fcblue); //CHANGED#0095E8
      padding:2px;
      border:none;
      text-align: center;
      width: 10px;
      height: 10px;
      display:inline-block;
    }
  }
}
.ReactModal__Overlay.customize-viewer3d-modal-overlay {
  background-color: rgba(0, 0, 0, 0);
}

.ReactModal__Overlay.confirm-modal-overlay {
  @include smartphone {
    top: 0px;
  }
}

.ReactModal__Content.pdfLoadingModal {
  width: 35%;
  height: 200px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.6);
  p{
    font-family: $sukhumvitFont;
    font-size: 16pt;
    font-weight: bold;
    color: var(--fcdarkstblue);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin-top: 30px;
  }
  i{
    display: none;
  }
}

.ReactModal__Overlay.familyspace-compare-modal-overlay {
  img {
    height: 100%;
  }
}
.ReactModal__Content.familyspace-compare-modal {
  margin: auto;
  height: 60%; width: 60%;
}

.ReactModal__Content.confirm-modal {
  width: 300px;
  height: 150px;
  margin: auto;
  margin-top: 0px!important;

  .modal-ask {
    text-align: center;
    margin-top: 30px;
    font-size: 16pt;
  }

  .modal-button {
    text-align: center;
    margin-top: 45px;

    button {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
}

.ReactModal__Content.customize-share-modal {
  width: 250px!important;
  height: 91px!important;
  background: #EFEFEF 0% 0% no-repeat padding-box;
  text-align: center;
  margin: auto;

  .CloseModal {
    display: none;
  }

  .SocialBar {
    background: #EFEFEF!important;
    height: 90px!important;
    padding-top: 0px;

    @include smartphone {
      padding-top: 20px;
    }

    button {
      padding: 10px;

      @include smartphone {
        margin-left: 10px;
      }

      .SocialLogoTitle {
        margin-top: 5px;
        font-family: Cuprum;
        font-size: 12pt;
      }
    }

    button.ShareEmailButton {
      background-color: rgba(239, 239, 239, 0);
      border-style: none;
    }
  }

  ul {
    margin-top: 5px;
    padding-left: 0;

    li {
      padding: 20px;
      display: inline-block;

      img {
        cursor: pointer;
      }

      div {
        margin-top: 5px;
        font-family: Cuprum;
        color: #3B4752;
      }
    }
  }
}

@keyframes slideDown {
  from {
    //transform: translateY(-10px);
    margin-top: -100px;
    opacity: 0;
  }
  to {
    //transform: translateY(0);
    margin-top: 0;
    opacity: 1;
  }
}
@keyframes slideUp {
  from{
    margin-top: 0;
    opacity: 1;
  }
  to{
    margin-top: -100px;
    opacity: 0;
  }
}

.Family{
  .shareBtnsContainer{
    position: absolute;
    top: 74px;
    right: 71em;
    z-index: 9;
    .shareTopBtn {
      height: 64px;
      width: 64px;
      border-radius: 50px;
      background-color: #324854;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 5px;
      cursor: pointer;
      visibility: visible;
      animation-duration: 1s;
      animation-name: slideDown;
      svg,img{
        overflow:unset;
        height: 24px;
        width: 24px;
      }
      &.waBtn{
        background-color: #25D366;
        &:hover{
          background-color: #1a9c4a;
        }
      }
      &.fbBtn{
        background-color: #4267B2;
        &:hover{
          background-color: #264686;
        }
      }
      &.fmBtn{
        background-color: #FF9D1C;
        svg{
          width: 32px;
          height: 32px;
        }
        &:hover{
          background-color: #9e6214;
        }
      }
      &.mailBtn{
        background-color: #6A7782;
        &:hover{
          background-color: #3a4046;
        }
      }
    }
  }
  .fcustomize{
    .floatingBtnsContainer{
      position: absolute;
      bottom: 20px;
      right: 30px;
      @media screen and (max-width:768px){
        bottom: 100px;
        z-index: 1;
        right: 5px;
        position: fixed;
        @supports (-webkit-touch-callout: none) {
          // bottom: 100px;
        }
      }
      .button-site-item-3d{
        width: 64px;
        height: 64px;
        border-radius: 20px;
        border-style: none;
        background: var(--fclightGray) 0% 0% no-repeat padding-box;
        color: #FFFFFF;
        font-family: Cuprum;
        font-size: 12pt;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 2px 5px 0 rgba(0 , 0 , 0 , 0.5);
        margin-bottom: 10px;
        &.saveicon{
          svg{
            width: 24px;
            height: 24px;
          }
        }
      }
      .flowersBtn{
        background-color: #EAEEF2;
      }
    }
    .floatingBtnsContainerMobile{
      position: fixed;
      bottom: 103px;
      right: 50%;
      z-index: 1;
      transform: translateX(50%);
      display: flex;
      flex-direction: column;
      .infoBtn{
        height: 36px;
        width: 299px;
        /* color: #6A7782; */
        color: var(--f-font-color);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 12px;
        background: var(--fclightGray);
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
        border-radius: 50px;
        .svgContainer{
          display: flex;
          margin: unset;
          svg{
            width:24px;
            height:24px;
            padding: 2px 3px;
          }
          span{
            font-weight: 400;
            font-size: 16px;
            margin-left: 8px;
            /* color: #001F2A; */
            align-self: center;
          }
        }
        &.btnQuoteAsk {
          /* background: var(--fcorange); */
          background: var(--f-main-color);
        }
      }
    }
    .FbigDetailB{
      /* top: 60px !important;
      left: unset !important;
      right: 222px !important;
      display: flex;
      overflow: unset;
      flex-direction: column;
      // min-height: 50vh;
      height: unset;
      font-family: $sukhumvitFont;
      width: 360px;
      box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
      border-radius: 8px !important;
      z-index: 10;
      padding-bottom: 12px; */
      @media screen and (max-width: 768px){
        width: 100%;
        top: 65px !important;
        left: 0 !important;
        right: unset !important;
        border-radius: unset !important;
      }
      &.FbigDetailB-open{
        display: block !important;
        z-index: 1000;
      }
      &.FbigDetailB-close{
        display: none !important;
      }
      &.quoteAskBtn {
        right: 13px !important;
      }
      .Fquote{
        .row{
          button{
            font-family: $RalewayFont;
            font-weight: bold;
          }
        }
      }
    }
  }
  .option-popup {
    position: fixed;
    bottom: 83px;
    right: 18px;
    background: white;
    width: 207px;
    max-height: 120px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
    border-radius: 4px; 
    ul {
      margin: 0;
      padding: 0;
      button {
        width: 100%;
        border: 0;
        height: 42px;
        text-align: start;
        padding: 10px 16px;
        color: #191C1E;
        &:focus {
          outline: 0;
          background-color: var(--fclightGray);
        }
      }
    }
  }
  .option-popup-overlay{
    position: fixed;
    top: 0;
    height: 100vh;
    width: 100vw;
  }
}

.ReactModal__Content.ReactModal__Content--after-open.backToCatalogModal{
  width: 90%;
  height: fit-content;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px 10px 0 10px;
  @media screen and (min-width: 480px){
    width: 350px;
    padding: 10px 20px 10px 20px
  }
  h1{
    color: #6A7782;
    font-family: $RalewayFont;
    font-size: 16pt;
    font-weight: 700;
  }
  p{
    color: var(--fcdarkstlblue);
    font-family: $sukhumvitFont;
    font-size: 14pt;
    line-height: 1.5em;
  }
  button{
    background: unset;
    border: unset;
    font-family: $sukhumvitFont;
    color: #6A7782;
    font-weight: bold;
    float: right;
  }
}
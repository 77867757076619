body {
    font-family: $RalewayFont;
    font-size: 63%;
}

button:focus {
    outline: none;
    box-shadow: none;
}

/* ========================================= NavBar: family space & configuration ========================================= */
.fcustomize, .top-navbar {
    .fTopBarContainer {
        z-index: 10;
        height: 4.375rem;
        background-color: #EAEEF2;
        color: #324854;
        font-size: 1.375rem;
        font-weight: 700;
        display: flex;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        box-shadow: rgba(0, 0, 0, 0.24) 0 0.0625rem 0.3125rem;
        width: 100%;
        .logoContainer {
            margin-top: unset;
            @media screen and (max-width: 36rem){
                margin-bottom: unset;
            }
            .clientLogo{
                margin-left: 0.9375rem;
                @media screen and (max-width: 48rem){
                    argin-left: 0.3125rem;
                }
            }
        }
        @media screen and (max-width: 30rem) {
            position: fixed;
            top: 0;
        }
        .monumentRef {
            font-family: $RalewayFont;
            padding-left: 2rem;
            color:  var(--fcpgray);
            font-size: 1.75rem;
            font-weight: 700;
            letter-spacing: 0.0313rem;
            @media screen and (max-width:36rem){
                font-size: 1.25rem;
                padding-left: 0.625rem;
            }
        }
        .FamilyInfo {
            color: #6A7782;
            position: relative;
            .familyInfoDetails {
                .familyName {
                    font-size: 1.75rem;
                    padding-top: 0.9375rem;
                    font-weight: 600;
                    line-height: 0.9375rem;
                }
                .familyEmail {
                    font-size: 1.125rem;
                    font-weight: 500;
                } 
                @media screen and (max-width: 36rem){
                    color: #fff;
                    height: 5.625rem;
                }
            }
        }
        .infosButtons {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: auto;
            margin-right: 0.3125rem;
            .infoBtn {
                // width: 3rem;
                // height: 3rem;
                margin-right: 0.5rem;
                color: #6A7782;
                cursor: pointer;
                fill: var(fc--orange);
                padding: 0.5rem 0.875rem;
                display: flex;
                align-items: center;
                justify-content: center;
                background: rgba(255, 255, 255, 0.5);
                border: 0.0625rem solid #4D616B;
                border-radius: 3.125rem;
                background-color: var(--f-main-color);
                @media screen and (max-width: 36rem){
                    margin-right: 0;
                }
                @media screen and (max-width: 48rem){
                    background: unset;
                    border: 0;
                    padding: 0 0.25rem;
                    color: #324854;
                }
                .svgContainer{
                    svg {
                        width:1.875rem;
                        height:1.875rem;
                        padding: 0.125rem 0.1875rem;
                    }
                    p {
                        display: flex;
                        margin: unset;
                        span {
                            font-weight: 500;
                            font-size: 1rem;
                            margin-left: 0.25rem;
                            color: var(--f-font-color);
                            align-self: center;
                            @media screen and (max-width: 48rem){
                                font-size: 0.875rem;
                            }
                        }
                        @media screen and (max-width: 48rem){
                            flex-direction: column;
                            align-items: center;
                        }
                    }
                }
                /* &.open{
                border: 0.0625rem solid #C1CABE;
                border-radius: 1.875rem;
                padding: 0.625rem;
                } */
                /*
                &.infoQuoteAsk {
                    // background-color: var(--fcorange);
                    background-color: var(--f-main-color);
                    border: 0;
                    .svgContainer{
                        span{
                            color: var(--f-font-color);
                        }
                    }
                }
                */
            }
        }
    }
}

/* ========================================= Button : family space ========================================= */
.fsp-button {
    background-color: var(--f-main-color) !important;
    color: var(--f-font-color) !important;
    cursor: pointer;
    border-radius: 3.125rem;
    height: 2.625rem;
    font-size: 0.875rem !important;
    border: unset;
    margin: 0 auto;
    text-align: center;
    font-weight: unset;
    padding: 0.625rem 15%;
    line-height: 1.5;
    font-family: "Raleway", cuprum, sans-serif !important;
    // text-transform: unset;
    font-weight: 700 !important;
    text-transform: uppercase !important;
    /* 
    border: 0.0625rem solid #4D616B;
    box-shadow: 0rem 0.0625rem 0.125rem rgba(0, 0, 0, 0.3), 0rem 0.125rem 0.375rem 0.125rem rgba(0, 0, 0, 0.15);
    */
    &:hover {
        /* 
        background: var(--f-font-color) !important;
        color: var(--f-main-color) !important;
        span {
            color: var(--f-main-color) !important;
        } 
        */
    }
    &.cancel {
        background: #E6E6E6 !important;
        color: #0E0E0F !important;
        &:hover {
            background-color: #545454 !important;
            color: white !important;
        }
    }
    span {
      color: var(--f-font-color);
    }
    .svgContainer {
      span {
        padding-left: 0.1875rem;
      }
    }
    &.grey {
      background: #6A7782 !important;
      color: #fff;
    }
    &.outline {
        background-color: unset;
        border: 0.0625rem solid;
        border-radius: 3.125rem;
        border-color: #4D616B !important;
    }
}

.fsp-link {
    color: #212529 !important;
    font-family: "sukhumvit", arial, helvetica, sans-serif !important;
    cursor: pointer !important;
    text-decoration: none !important;
    border: none;
    &:hover {
        color: var(--fcpgray) !important;
    }
}

.btn-no-hover {
    &:hover {
        background-color: var(--f-main-color);
        color: var(--f-font-color);
        span {
            color: var(--f-font-color);
        }
    }
}

/* ========================================= Button : family space ========================================= */
.FbigDetailB { 
    top: 3.75rem;
    left: unset !important;
    right: 13.875rem !important;
    overflow: unset;
    flex-direction: column;
    height: unset !important;
    font-family: "sukhumvit", arial, helvetica, sans-serif;
    /* width: 22.5rem; */
    box-shadow: 0 0.875rem 1.75rem rgba(0, 0, 0, 0.25), 0 0.625rem 0.625rem rgba(0, 0, 0, 0.22);
    border-radius: 0.5rem !important;
    padding-bottom: 0.75rem;
    height: unset;
    background-color: white;
    -webkit-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
    position: absolute;
    @media screen and (min-width: 36rem){
        position: absolute;
        left: 6.875rem !important;
        width: 22.5rem;
        top: 4.375rem !important;
      }
    @media screen and (max-width: 41.125rem){
        left: 0rem !important;
        //top: 2.25rem !important;
        width: 96%;
    }
    @media screen and (max-width: 48rem){
        z-index: 99;
    }
    .mobilePrices{
        margin-top: inherit;
    }
    &.fsp {
        padding-top: 3.125rem;
        padding-right: 0.625rem;
        border-radius : 0.375rem !important;
        overflow: unset;
        z-index: 999;
        position: absolute;
        margin-left: 0.625rem;
    }
    .prices {
        top: 15%;
        right: 1.1875rem;
        background: #FFFFFF;
        font-size: 1rem;
        color: #707070;
        margin-bottom: 0.9375rem;
        padding-left: 0.9375rem;
        padding-right: 0.9375rem;
        .price {
            color: var(--fcdarkstlblue);
            text-align: right;
            padding-top: 0.625rem;
            span {
                font-weight: 700;
                color: #707070;
            }
        }
        .packagePrice {
            font-style: italic;
            font-size: 0.875rem;
            text-align: right;
        }
        
    }
    .Fquote {
        width: 100%;
        box-shadow: unset;
        margin-top: 0.9375rem;
        padding: 0.3125rem 0.75rem 0.3125rem 0.75rem;
        .row {
            margin-left: 0;
            margin-right: 0;
            button {
                width: 100%;
            }
        }
    }
    .FHelper-container {
        .FquoteHelp {
            color: var(--fcdarkstlblue);
            font-size: 1rem;
            font-weight: bold;
            margin: 0.625rem 0;
            padding: 0 0.9375rem;
            position: relative;
            cursor: pointer;
        }
        .FamilyHelpText {
            font-size: 1rem;
            font-weight: bold;
            background: #EFEFEF !important;
            padding: 0.9375rem;
            width: 22.5rem;
            position: absolute;
            right: calc( 100% + 1.25rem);
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            border-radius: 0.5rem;
            font-family: "sukhumvit", arial, helvetica, sans-serif;
            .arrow {
                border: solid 0.625rem transparent;
                border-left-color: #EFEFEF;
                position: absolute;
                left: unset;
                right: 0;
                top: 50%;
            }
            .itemText {
                color: #3B4752;
                font-size: 0.875rem;
                font-weight: normal;
                list-style: none;
            }
        }
    }
}

/* ========================================= Common project (Header) : family space & configuration ========================================= */
.SprojectCommon {
    @include smartphone{padding-left: unset; padding-right: unset;} // à verifier
    flex:50%;
    &.full {
        flex:100%;
        max-width: unset;
        height: calc(100vh - 5rem);
        overflow-y: auto;
        margin-top: 1.25rem;
        .LeftOvalAction {
          position: absolute;
          top: 6.5625rem;
          padding: 0.625rem 3.125rem;
          @media screen and (max-width:36rem){
            top: 5.3125rem;
            left: 1.5625rem;
            padding: 0.625rem 0.625rem;
          }
        }
        .prices {
          .LeftOvalAction {
            bottom: 0.625rem;
            left: 0;
          }
        }
        &.full::-webkit-scrollbar {
            width: 0.3125rem;               /* width of the entire scrollbar */
        }
        &.full::-webkit-scrollbar-track {
            background: #F0F0F0;        /* color of the tracking area */
            border-radius: 0.625rem;
        }
        &.full::-webkit-scrollbar-thumb {
            background-color: #E0E0E0;    /* color of the scroll thumb */
            border-radius: 0.625rem;       /* roundness of the scroll thumb */
        }
    }
    .Sproject {
        border-radius: 0.3125rem;
        margin:0 0 1.25rem 0;
        border-radius: 0.3125rem;
        &.full {
            border: none !important;
            box-shadow: unset;
            margin-right: 0.3125rem;
            margin-left: 0.3125rem;
            background-color: unset;
            .SprojectHeader {
                padding-bottom: 0.1875rem;
                display: flex;
                /* align-items: center; */
                flex-direction: column;
                position: absolute;
                padding-left: 0.9375rem;
            }
        }
        .SprojectHeader {
            padding-bottom: 0.1875rem;
            display: block;
            font-family: "sukhumvit", arial, helvetica, sans-serif;
            &.full {
                display: flex;
                /* align-items: center; */
                flex-direction: column;
                position: absolute;
                padding-left: 0.9375rem;
            }
            .headerUp {
                display: flex;
                align-items: center;
                padding-top: 0.3125rem;
                padding-bottom: 0.3125rem;
                height: 2.8125rem;
                z-index: 9;
                position: relative;
                .title{
                    font-size: 1.6875rem;
                    color: #2A3032;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            .headerDown {
                display: flex;
                align-items: center;
                padding-top: unset;
                .ProjectPrice {
                    display: flex;
                    align-items: center;
                    margin-left: 0.3125rem;
                    padding-left: 0.3125rem;
                    padding-right: 0.3125rem;
                    z-index: 10;
                    .Topprice {
                        font-size: 1.0625rem;
                        color: #6A7782;
                    }
                }
            }
        }
    }
}

/* ========================================= Modals ========================================= */
// ModalAskQuote
.modal-dialog.ModalAskQuote {
    margin-top: 35vh !important;
    margin-left: auto !important;
    max-width: 37.5rem;
    @media screen and (max-width: 36rem) {
        margin-top: 0 !important;
    }
    .modal-content {
        width: 37.5rem;
        @media screen and (max-width: 36rem) {
            width: 100%;
        }
    }
}

// ModalDisplayMsg
.modal-display-msg {
    width: 37.5rem;
    padding: 0rem 1.4375rem 1.875rem 1.4375rem !important;
    text-align: center;
    height: fit-content !important;
    margin: auto !important;
    font-size: 1.25rem;
    @media screen and (max-width: 36rem) {
        width: 90%;
    }
    p {
        font-size: 1.75rem;
        color: #2A3032;
        padding-top: 3.375rem;
        font-size: 1.25rem;
    }
}

/* ========================================= fonte family (les champs) : family space & configuration ========================================= */
.Family, .familyspace-member-name-modal-overlay, .ModalInvite, .SselectProjects, .SchangeName {
    input, .ConfigurationCompletion .Field .FieldContainer, textarea, select {
        font-family: "sukhumvit", arial, helvetica, sans-serif !important;
    }
}

/* ========================================= Loader ========================================= */

.loadingContainer {
    margin: auto;
    position: absolute;
    top: 50%;
    -ms-transform: translate(-50%);
    transform: translate(-50%, -50%);
    left: 50%;
  
    .loadingImg {
      width: 9.375rem;
  
      @media screen and (max-width: 36rem) {
        width: 8.4375rem;
      }
    }
}

// ========================================= RadioButton ========================================= //
.radio-input {
    visibility: hidden;
    display: none;
}
.radio-label {
    position: relative;
    padding-left: 2.1875rem;
    .radio-border {
        width: 1.25rem;
        height: 1.25rem;
        display: inline-block;
        outline: solid 0.1875rem var(--text-dark);
        border-radius: 50%;
        position: absolute;
        left: 0rem;
        top: 0rem;
    }
    &:after {
        content: "";
        display: block;
        width: 0.875rem;
        height: 0.875rem;
        position: absolute;
        left: 0.1875rem;
        top: 0.1875rem;
        border-radius: 50%;
    }
}
.radio-input:checked + .radio-label:after {
    transition: all 0.5s;
    background-color: var(--text-dark);
}

// ========================================= checkbox ========================================= //
.checkbox {
    label {
      transition: 0.3s;

      border-color: var(--text-dark);
    }
    .custom-checkbox {
      position: absolute;
      z-index: -1;
      opacity: 0;
    }
    .custom-checkbox + label {
      display: inline-flex;
      align-items: center;
      user-select: none;
    }
    .custom-checkbox + label::before {
      border: 0.0625rem solid  var(--text-dark);
      transition: 0.3s;
      content: "";
      display: inline-block;
      width: 1.25rem;
      height: 1.25rem;
      flex-shrink: 0;
      flex-grow: 0;
      border-radius: 0.25rem;
      margin-right: 0.5em;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 50% 50%;
    }
    .custom-checkbox:checked + label::before {
      border-color: var(--text-dark);
      background-color: var(--text-dark);
      background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2212%22%20height%3D%2210%22%20viewBox%3D%220%200%2012%2010%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M10%202L4.5%208L2%205.27273%22%20stroke%3D%22white%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22square%22%2F%3E%0A%3C%2Fsvg%3E%0A");
    }
}

// ========================================= input ========================================= //
.input-wrapper {
    display: flex;
    align-items: center;
    border: 0.0625rem solid var(--border-color);
    border-radius: 0.625rem;
    // padding: 0.3125rem;
    padding: 0.5rem 1rem;
    background: var(--background-page);
    font-family: "Raleway", cuprum, sans-serif !important;
    width: 100%;
    .custom-input-field {
        border: none;
        outline: none;
        padding: 0.3125rem;
        flex-grow: 1;
        font-size: 1rem;
    }
    span.custom-input-addon {
        padding-right: 0.625rem;
        font-size: 1rem;
    }
}

// ========================================= button secondary ========================================= //
.custom-secondary-button {
    margin: 0rem 0.75rem;
    padding: 0.375rem 0.75rem;
}

// ========================================= buttons group ========================================= //
.buttons-group {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.75rem;
    border: 0;
    button {
      width: 13.75rem;
      color: var(--text-medium);
      // margin-left: 0.625rem;
      // margin-right: 0.625rem;
      // border-radius: 0.625rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0.0625rem solid #c8c8c8 !important;
      border-radius: 0;
      margin: 0;
      i.icon {
        width: unset;
        display: inline-flex;
        margin: unset;
        @include smartphone{display: none;} 
      }
      span{
        margin-left: 0.625rem;
      }
      &.selectedKind, &.active {
        background-color: var(--secondary2);
        color: var(--text-light);
        &:hover {
          background: var(--secondary2);
        }
      }
      &:first-child {
        border-radius: 0.375rem 0 0 0.375rem;
      }
      &:last-child {
        border-radius: 0 0.375rem 0.375rem 0;
      }
    }
}

// ========================================= gpgConfigurator3d ========================================= //
#gpgConfigurator3d {
    background-color: var(--background-page);
}
